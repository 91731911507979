:root {
  --light-navy: #3f4f72;
  --navy: #2d3b5b;
  --drak-navy: #1f2636;
  --black: #101828;
  --white: #fff;
  --grey-50: #fafafa;
  --grey-100: #f2f2f2;
  --grey-200: #e2e2e5;
  --grey-300: #d9d9d9;
  --grey-400: #cccccc;
  --grey-500: #bebebe;
  --grey-600: #a3a3a3;
  --grey-700: #8e8e93;
  --grey-800: #6e6e72;
  --grey-900: #3c3c43;
  --gray-200: #eaecf0;
  --gray-600: #475467;
  --neutral: #666;
  --breadcrumbs: #9ca3af;
  --background: #fcfcfc;
  --blue-background: #f0f7ff;
  --light-grey: #f0f0f0;
  --hover-menu: #f7fafe;
  --discover-card: #007aff1a;
  --alert: #8a6d3b;
  --alert-background: #fff8eb;
  --red: #d1321f;
  --green: #8cb838;
  --purple: #621999;
  --blue: #224782;
}

/* html {
  scroll-behavior: smooth;
} */

body {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
