.page {
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 100%;
}

.page-header {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.page-title {
  font-size: 48px;
  font-weight: 700;
  color: var(--navy);
}

.page-description {
  font-size: 18px;
  color: var(--grey-700);
}


@media screen and (max-width: 1200px) {
  .page {
    gap: 32px;
  }

  .page-title {
    font-size: 42px;
  }

  .page-description {
    font-size: 16px;
  }
}

@media screen and (max-width: 900px) {
  .page {
    gap: 24px;
  }

  .page-header {
    gap: 16px;
  }

  .page-title {
    font-size: 38px;
  }

  .page-description {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .page {
    gap: 16px;
  }

  .page-header {
    gap: 8px;
  }

  .page-title {
    font-size: 32px;
  }
}