.reset-password__logo {
  width: 174px;
  user-select: none;
  -webkit-user-drag: none;
}

.reset-password__input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}
.reset-password__input-label {
  font-size: 14px;
  font-weight: 500;
}
.reset-password__input-error {
  font-size: 12px;
  font-weight: 400;
  color: red;
}

.forgot-password {
  font-size: 14px;
  font-weight: 600;
  color: var(--gray-700);
  width: fit-content;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
}

.reset-password__hide-part {
  height: 32px;
  width: 100%;
}
