/* Global */
#main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--background);
}

#content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 16px;
}
.content {
  padding: 48px;
}

.go-back-arrow {
  display: flex;
  align-self: self-start;
  cursor: pointer;
}

/* Header */
.header-part {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 48px;
}

.header-logo--container {
  display: flex;
}
.header-logo {
  width: 139px;
}

.header-connect-buttons {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
}

.header-menu-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.header-menu-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: var(--navy);
  font-weight: 600;
  width: 270px;
}
.header-menu-section--title {
  font-size: 14px;
  margin-left: 12px;
  margin-bottom: 12px;
}
.header-menu-section--item {
  display: flex;
  gap: 12px;
  padding: 12px;
  border-radius: 4px;
  transition: all 0.2s;
  color: var(--navy);
  text-decoration: none;
}
.header-menu-section--item:hover {
  background-color: var(--hover-menu);
}
.header-menu-section--item__icon {
  height: 24px;
  width: 24px;
  border-radius: 2px;
  object-fit: contain;
}
.header-menu-section--item__content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header-menu-section--item__label {
  font-size: 16px;
  line-height: 24px;
}
.header-menu-section--item__description {
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  color: var(--neutral);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.side-bar--text {
  flex: 1;
  color: var(--navy);
  font-size: 18px;
  font-weight: 600;
}

/* Chevron */
.chevron {
  transition: transform 0.3s;
}
.chevron.up {
  transform: rotate(270deg);
}
.chevron.down {
  transform: rotate(180deg);
}

/* Footer */
.footer__not-auth--container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 80px 64px;
}
.footer__not-auth--blue-background {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 120px;
  background-color: var(--blue-background);
}

.footer__not-auth {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 96px 64px;
  width: 100%;
  border-radius: 8px;
  background-color: var(--navy);
  z-index: 1;
  overflow: hidden;
}
.footer__not-auth--title {
  color: white;
  font-size: 48px;
  font-weight: 700;
  text-align: center;
}
.footer__not-auth--actions {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.footer__not-auth--background-e {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.footer__not-auth--background-p {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
}

.footer {
  display: flex;
  padding: 80px 64px;
  gap: 64px;
  background-color: var(--blue-background);
}

.footer__epopi-infos {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 184px;
}

.footer__epopi-logo {
  width: 173px;
}

.footer__epopi-description {
  font-size: 14px;
  font-weight: 700;
}
.footer__epopi-description > span {
  font-size: 12px;
  font-weight: 600;
}

.footer__partners {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}
.footer__partners img {
  max-height: 48px;
  object-fit: contain;
}

.footer__links {
  display: grid;
  grid-template-columns: 1.7fr 1fr 1fr;
  gap: 32px;
}

.footer__link-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.footer__link-item--title {
  font-size: 12px;
  font-weight: 600;
  color: black;
  text-transform: uppercase;
}
.footer__link-item--links {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.footer__link-item--links > a {
  font-size: 14px;
  font-weight: 600;
  color: black;
  text-decoration: none;
}

/* Search bar */
.search-bar {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex: 1;
  max-width: 600px;
  min-width: 250px;
  gap: 16px;
}
.side-bar--search {
  display: flex;
  padding: 12px;
}
.search-bar__input-end {
  display: flex;
  padding: 8px;
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: var(--navy);
  cursor: pointer;
}
.search-bar__option {
  display: flex;
  gap: 8px;
  align-items: center;
  text-decoration: none;
}
.search-bar__option--name {
  font-size: 16px;
  font-weight: 600;
  color: var(--navy);
}

/* Others roots elements */
.breadcrumbs-link {
  display: flex;
}
.breadcrumbs-item {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}
.breadcrumbs-item.link {
  color: var(--breadcrumbs);
}
.breadcrumbs-item.text {
  color: black;
}

.result-infos__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
}
.loading {
  padding: 24px;
}
.no-data__text {
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
  color: var(--gray-700);
  margin: 0;
}

.modal-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
}
.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--white);
}
.modal-container.small {
  max-width: 400px;
}
.modal-container.medium {
  max-width: 600px;
}
.modal-container.large {
  max-width: 800px;
}
.modal-container.fullWidth {
  max-width: 100%;
}

/* Colors classes */
.red {
  color: var(--red) !important;
}
.green {
  color: var(--green) !important;
}
.blue {
  color: var(--blue) !important;
}
.purple {
  color: var(--purple) !important;
}
.navy {
  color: var(--navy) !important;
}

.background-red {
  color: white !important;
  background-color: var(--red) !important;
}
.background-green {
  color: white !important;
  background-color: var(--green) !important;
}
.background-blue {
  color: white !important;
  background-color: var(--blue) !important;
}
.background-purple {
  color: white !important;
  background-color: var(--purple) !important;
}
.background-navy {
  color: white !important;
  background-color: var(--navy) !important;
}
.background-white {
  color: var(--navy) !important;
  background-color: white !important;
}

.border-bottom-red {
  border-bottom: 1px solid var(--red) !important;
}
.border-bottom-green {
  border-bottom: 1px solid var(--green) !important;
}
.border-bottom-blue {
  border-bottom: 1px solid var(--blue) !important;
}
.border-bottom-purple {
  border-bottom: 1px solid var(--purple) !important;
}
.border-bottom-navy {
  border-bottom: 1px solid var(--navy) !important;
}

.li-red li::marker {
  color: var(--red) !important;
}
.li-green li::marker {
  color: var(--green) !important;
}
.li-blue li::marker {
  color: var(--blue) !important;
}
.li-purple li::marker {
  color: var(--purple) !important;
}
.li-navy li::marker {
  color: var(--navy) !important;
}

/* Media screen */
@media screen and (max-width: 1200px) {
  /* Header */
  .header-part {
    gap: 16px;
  }

  .header-logo {
    width: 120px;
  }
}
@media screen and (max-width: 1000px) {
  /* Footer */
  .footer__not-auth--container {
    padding: 64px 32px;
  }
  .footer {
    padding: 64px 32px;
  }

  .footer__not-auth--background-e,
  .footer__not-auth--background-p {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  /* Header */
  .header-logo {
    width: 110px;
  }

  /* Footer */
  .footer__links {
    display: flex;
    flex-direction: column;
  }

  /* Other roots elements */
  .modal-main-container {
    padding: 24px;
  }
}

@media screen and (max-width: 768px) {
  /* Global */
  .content {
    padding: 24px 16px;
  }

  /* Header */
  .header-logo {
    width: 104px;
  }

  /* Footer */
  .footer__not-auth--container {
    padding: 40px 16px;
  }

  .footer__not-auth {
    padding: 40px 24px;
  }
  .footer__not-auth--title {
    font-size: 32px;
  }

  .footer {
    flex-direction: column;
    gap: 40px;
    padding: 40px 16px;
  }

  .footer__epopi-infos {
    width: 100%;
  }

  .footer__epopi-logo {
    width: 139px;
  }

  .footer__links {
    display: flex;
    flex-direction: column;
  }

  /* Other roots elements */
  .search-bar {
    width: 100%;
    max-width: unset;
  }

  .breadcrumbs-item {
    font-size: 14px;
  }

  .modal-main-container {
    padding: 16px;
  }

  .dual-input-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  /* Header */
  .header-menu-section--item__icon {
    height: 22px;
    width: 22px;
  }

  .header-part {
    gap: 8px;
  }
}
