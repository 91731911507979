.subscription-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.subscription-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border: 1px solid var(--grey-200);
}

.subscription-section__title {
  font-size: 24px;
  font-weight: 700;
}

.subscription-section__content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media screen and (max-width: 768px) {
  .subscription-section {
    padding: 16px;
    gap: 16px;
  }

  .subscription-section__title {
    font-size: 20px;
    font-weight: 600;
  }

  .subscription-section__content {
    gap: 16px;
  }
}