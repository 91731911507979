
.valid-email__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  text-align: center;
}

.valid-email__title {
  font-size: 24px;
  font-weight: 700;
  color: var(--navy);
  text-align: center;
}