.settings-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  gap: 24px;
}

.settings-container {
  display: grid;
  grid-template-columns: 316px 1fr;
  gap: 16px;
  flex: 1;
}

.settings-menu {
  width: 100%;
  height: min-content;
}

.settings-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.settings-section__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 42px 32px;
  border: 1px solid var(--grey-200);
  flex: 1;
}

.settings-section__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.settings-section__header-title--container {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: nowrap;
}

.settings-section__header-title {
  font-size: 24px;
  font-weight: 500;
}

.settings-section__header-cta--container {
  display: flex;
  gap: 8px;
}

.settings-section__content-cta--container {
  display: none;
}

.settings-section__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media screen and (max-width: 899px) {
  .settings-main-container {
    height: calc(100vh - 48px - 56px);
  }

  .settings-container {
    grid-template-columns: 1fr;
  }

  .settings-section__container {
    padding: 0;
    border: none;
  }

  .settings-section__header-title--container.has-arrow {
    margin-left: -10px;
  }

  .settings-section__header-cta--container {
    display: none;
  }

  .settings-section__content-cta--container {
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  .settings-section__content {
    margin-bottom: 16px;
  }
}

.invoices-container {
  display: inline-block;
  justify-content: flex-start;
  max-height: 400px;
  overflow-y: scroll;
}

.invoice-container {
  display: inline-block;
  border: 1px solid var(--grey-300);
  padding: 20px;
  width: 200px;
  margin: 5px;
}

.invoice-container:hover {
  background-color: var(--grey-200);
}

.invoices-field {
  font-size: 16px;
}

.invoice-title-container {
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  overflow: hidden;
}

.invoice-title {
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
