.connect-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 200px;
  max-width: 480px;
  height: 100%;
}

.connect-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  padding: 24px 0;
  text-align: center;
}

.connect-title {
  font-size: 32px;
  font-weight: 700;
}

.form {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 24px;
  width: 100%;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}
.input-label {
  font-size: 16px;
}
.forgot-password {
  font-size: 14px;
  font-weight: 600;
  color: black;
  text-decoration: none;
}
