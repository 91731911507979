.faq-content {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  gap: 48px 64px;
  width: 100%;
}

.faq-question__title {
  font-size: 18px;
  font-weight: 700;
}

.faq-question__answer {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .faq-content {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .faq-question__title, .faq-question__answer {
    font-size: 16px;
  }

  .faq-question__answer {
    margin-bottom: 24px;
  }
}