.abbreviations-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  width: 100%;
  max-width: 900px;
}

.abbreviations-title__container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.abbreviations-title {
  font-size: 32px;
  font-weight: 600;
}

.abbreviations-alphabet-container {
  position: sticky;
  top: 55px;
  display: flex;
  flex-wrap: nowrap;
  gap: max(2%, 16px);
  padding: 16px 0;
  overflow-y: auto;
  background-color: var(--background);
}
.abbreviations-alphabet-item {
  font-size: 16px;
  font-weight: 500;
  color: var(--grey-600);
  cursor: pointer;
  text-transform: capitalize;
  user-select: none;
}
.disabled {
  color: var(--grey-300);
  cursor: inherit;
}

.abbreviations-result {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.abbreviations-result-item {
  display: grid;
  grid-template-columns: minmax(75px, auto) 1fr;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--light-grey);
  background-color: white;
}

.abbreviations-result-item__abreviation {
  font-size: 16px;
  font-weight: 600;
  text-wrap: nowrap;
}

.abbreviations-result-item__name {
  font-size: 14px;
}


@media screen and (min-width: 600px) {
  .abbreviations-alphabet-container {
    top: 72px;
  }
}

@media screen and (min-width: 768px) {
  .abbreviations-container {
    gap: 24px;
  }

  .abbreviations-title {
    font-size: 40px;
    font-weight: 700;
  }

  .abbreviations-result-item {
    grid-template-columns: minmax(90px, auto) 1fr;
    gap: 24px;
    padding: 16px 24px;
  }

  .abbreviations-alphabet-container {
    top: 70px;
    padding: 24px 0;
  }

  .abbreviations-result-item__abreviation {
    font-size: 18px;
  }

  .abbreviations-result-item__name {
    font-size: 18px;
  }
}

@media screen and (min-width: 900px) {
  .abbreviations-alphabet-container {
    top: 76px;
  }
}
