/* Global */
.content {
  padding: 80px 64px;
}

/* Hero */
.hero {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  padding: 64px;
  color: white;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(9, 10, 31, 0.6) 100%
    ),
    url("../../assets/images/hero-background.jpeg");
  background-position: center;
  background-size: cover;
}

.hero__cmit-logo {
  width: 125px;
}

.hero__title--container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.hero__title {
  font-size: 65px;
  font-weight: 700;
  line-height: 100%;
  text-shadow: 0px 2px 4px 0px #00000040;
}
.hero__description {
  font-size: 20px;
  text-shadow: 0px 2px 4px 0px #00000040;
}

.hero-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}

.hero-not-auth {
  display: flex;
  gap: 32px;
}

.hero-partners {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 32px;
}
.hero-partners--title {
  font-size: 14px;
  font-weight: 600;
}
.hero-partners--img {
  max-height: 72px;
  object-fit: contain;
}

/* Discover */
.discover {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  padding-bottom: 110px;
}

.discover__title {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.discover__title--main {
  font-size: 40px;
  font-weight: 700;
}
.discover__title--sub {
  font-size: 20px;
  font-weight: 600;
  color: var(--gray-600);
}

.discover__content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}

.discover-card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 24px 61px -14px var(--discover-card);
}

.discover-card__icon {
  width: 80px;
  height: 80px;
  padding: 20px;
  border-radius: 50%;
  background-color: var(--discover-card);
  object-fit: contain;
}
.discover-card__title {
  font-size: 24px;
  font-weight: 700;
}
.discover-card__description {
  font-size: 16px;
  color: #062126cc;
}

/* Categories */
.categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  width: 100%;
}
.categories.not-auth {
  background-color: var(--blue-background);
}

.categories__title {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}

.categories__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;
}
.categories__content--small {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;
}

.categories-card {
  position: relative;
  display: flex;
  gap: 16px;
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
  text-decoration: none;
  transition: all 0.2s;
}
.categories-card:hover {
  transform: scale(1.02);
}
.categories-card.medium {
  align-items: center;
}
.categories-card.small {
  align-items: flex-start;
}

.categories-card__icon {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.categories-card__content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.categories-card__content.medium {
  justify-content: center;
}
.categories-card__content.small {
  justify-content: flex-start;
}

.categories-card__content--title {
  display: flex;
  gap: 8px;
  align-items: center;
}
.categories-card__icon-small {
  display: none;
}
.categories-card__title {
  font-size: 24px;
}
.categories-card__title.small {
  font-size: 20px;
}
.categories-card__description {
  font-size: 14px;
}

.categories-card__arrow-icon {
  position: absolute;
  bottom: 0px;
  right: 8px;
  transform: rotate(45deg);
}

/* Recent sheets */
.recent-sheets {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.recent-sheets__title {
  font-size: 40px;
  width: 100%;
}

.recent-sheets__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;
}

.recent-sheet__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--grey-100);
  box-shadow: 0px 2px 4px -2px #1018280f, 0px 4px 8px -2px #1018281a;
  text-decoration: none;
  transition: all 0.2s;
}

.recent-sheet__item--icon {
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 4px;
  object-fit: contain;
}

.recent-sheet__item--content {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.recent-sheet__item--title {
  font-size: 20px;
  font-weight: 600;
  color: var(--black);
  width: 100%;
}

.recent-sheet__item--last-update {
  font-size: 14px;
  color: var(--neutral);
  text-wrap: nowrap;
}

/* ePOPI on smartphone */
.on-smartphone {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  width: 100%;
}

.on-smartphone__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.on-smartphone__title {
  font-size: 40px;
  text-align: center;
}
.on-smartphone__description {
  font-size: 18px;
  text-align: center;
  color: var(--neutral);
  max-width: min(70%, 800px);
}

.on-smartphone__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.on-smartphone__steps {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 350px 1fr;
  gap: 48px;
  width: 100%;
}

.on-smartphone__steps-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.on-smartphone__fake-mockup {
  width: 100%;
  height: 540px;
}

.on-smartphone__mockup-container {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 1;
}
.on-smartphone__mockup--image {
  width: 264px;
  height: 540px;
  object-fit: contain;
  user-select: none;
  -webkit-user-drag: none;
}

.on-smartphone__step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 300px;
  text-align: center;
}

.on-smartphone__step--icon {
  display: none;
}
.on-smartphone__step--icon__number {
  font-size: 32px;
  font-weight: 700;
  color: var(--drak-navy);
}

.on-smartphone__step--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.on-smartphone__step--label {
  font-size: 24px;
  font-weight: 700;
  color: var(--drak-navy);
}

.on-smartphone__step--number {
  font-size: 16px;
  color: var(--neutral);
}

@media screen and (max-width: 1000px) {
  /* Global */
  .content {
    padding: 64px 32px;
  }
  /* Categories */
  .categories-card__icon {
    width: 60px;
    height: 60px;
  }

  .categories-card__title {
    font-size: 22px;
  }
  .categories-card__title.small {
    font-size: 16px;
  }

  /* ePOPI on smartphone */
  .on-smartphone__steps {
    grid-template-columns: 1fr 1fr;
    gap: 64px;
  }

  .on-smartphone__fake-mockup {
    display: none;
  }

  .on-smartphone__mockup-container {
    grid-column: 1/3;
    position: relative;
  }

  .on-smartphone__step {
    height: 100%;
  }
}

@media screen and (max-width: 900px) {
  /* Discover */
  .discover__content {
    display: flex;
    flex-direction: column;
  }

  /* Categories */
  .categories__title {
    font-size: 32px;
  }

  .categories-card {
    padding: 16px;
  }

  .categories-card__title {
    font-size: 20px;
    font-weight: 600;
  }
  .categories-card__title.small {
    font-size: 14px;
    font-weight: 600;
  }

  /* Recent sheets */
  .recent-sheets__content {
    grid-template-columns: 1fr;
  }

  .recent-sheets__title {
    font-size: 32px;
  }
}

@media screen and (max-width: 768px) {
  /* Global */
  .content {
    padding: 40px 16px;
  }

  /* Hero */
  .hero {
    padding: 72px 16px 32px;
  }

  .hero__cmit-logo {
    width: 100px;
  }

  .hero__title {
    font-size: 40px;
  }
  .hero__description {
    font-size: 18px;
  }

  .hero-footer {
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    flex: 1;
  }

  .hero-not-auth {
    flex-direction: column;
    gap: 8px;
  }

  .hero-partners {
    gap: 16px;
  }
  .hero-partners--img {
    max-height: 50px;
  }

  /* Discover */
  .discover {
    padding-bottom: 70px;
  }

  .discover__title {
    gap: 16px;
  }

  .discover__title--main {
    font-size: 24px;
  }
  .discover__title--sub {
    font-size: 16px;
  }

  .discover-card {
    padding: 16px;
  }

  .discover-card__title {
    font-size: 20px;
  }

  /* Categories */
  .categories {
    gap: 24px;
  }

  .categories__title {
    font-size: 24px;
  }

  .categories__content {
    grid-template-columns: 1fr;
    gap: 8px;
  }
  .categories__content--small {
    gap: 8px;
  }

  .categories-card__icon {
    display: none;
  }
  .categories-card__icon-small {
    display: block;
    height: 24px;
    width: 24px;
  }

  .categories-card__arrow-icon {
    display: none;
  }

  /* Recent sheets */
  .recent-sheets__title {
    font-size: 24px;
  }

  .recent-sheet__item {
    padding: 8px;
  }

  .recent-sheet__item--content {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0;
  }

  .recent-sheet__item--title {
    font-size: 16px;
  }

  .recent-sheet__item--last-update {
    font-size: 12px;
  }

  /* ePOPI on smartphone */
  .on-smartphone {
    gap: 48px;
  }

  .on-smartphone__header {
    gap: 20px;
  }
  .on-smartphone__title {
    font-size: 24px;
    text-align: left;
    color: var(--navy);
    width: 100%;
  }
  .on-smartphone__description {
    font-size: 16px;
    text-align: left;
    width: 100%;
    max-width: 100%;
  }

  .on-smartphone__content {
    gap: 48px;
  }

  .on-smartphone__tabs {
    width: 100%;
    max-width: 400px;
  }

  .on-smartphone__steps {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .on-smartphone__steps-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .on-smartphone__step {
    gap: 16px;
  }

  .on-smartphone__step--icon {
    display: block;
    width: 48px;
    height: 48px;
  }
  .on-smartphone__step--icon__number {
    display: none;
  }

  .on-smartphone__step--label {
    font-size: 20px;
    color: var(--navy);
  }
}

@media screen and (max-width: 600px) {
  /* ePOPI on smartphone */
  .on-smartphone__step {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    max-width: 100%;
    text-align: left;
  }

  .on-smartphone__step--content {
    align-items: flex-start;
    width: 100%;
  }
}
