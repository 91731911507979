.categories-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  width: 100%;
  max-width: 900px;
}

.categories-title__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.categories-title {
  font-size: 32px;
  font-weight: 600;
}

.categories-alphabet-container {
  position: sticky;
  top: 55px;
  display: flex;
  flex-wrap: nowrap;
  gap: max(2%, 16px);
  padding: 16px 0;
  overflow-y: auto;
  background-color: var(--background);
}
.categories-alphabet-item {
  font-size: 16px;
  font-weight: 500;
  color: var(--grey-600);
  cursor: pointer;
  text-transform: capitalize;
  user-select: none;
}
.disabled {
  color: var(--grey-300);
  cursor: inherit;
}

.categories-result {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.categories-result-item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--light-grey);
  background-color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}
.categories-result-item:hover {
  background-color: var(--grey-50);
}
.categories-result-item.disabled {
  background-color: var(--light-grey);
}

.mentor-pro-button {
  font-size: 24px;
  font-weight: 800;
}

@media screen and (min-width: 600px) {
  .categories-alphabet-container {
    top: 72px;
  }
}

@media screen and (min-width: 768px) {
  .categories-container {
    gap: 24px;
  }

  .categories-title {
    font-size: 40px;
    font-weight: 700;
  }

  .categories-result-item {
    gap: 24px;
    padding: 16px 24px;
  }

  .categories-alphabet-container {
    top: 70px;
    padding: 24px 0;
  }
}

@media screen and (min-width: 900px) {
  .categories-alphabet-container {
    top: 76px;
  }

  .categories-title__container {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
}
