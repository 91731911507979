.subscription-recap-main {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 600px;
}

.subscription-recap-title {
  font-size: 32px;
  font-weight: 700;
}

.subscription-recap-subtitle {
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}

.subscription-recap-alert__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--alert);
  background-color: var(--alert-background);
  color: var(--alert);
  font-size: 16px;
}

.subscription-recap-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.subscription-recap-section__title {
  font-size: 20px;
  font-weight: 600;
}

.subscription-recap-section__content, .subscription-recap-section__content__body {
  width: 100%;
}

.subscription-recap-section__content__body__row {
  display: table-row;
  table-layout: fixed;
}

.subscription-recap-section__content__body__row.header-text > td {
  text-align: center;
  font-weight: 500;
  padding: 8px 16px;
}

.subscription-recap-section__content__body__row > td {
  padding: 8px;
  border: 1px solid var(--light-grey);
  border-top: none;
  border-left: none;
}
.subscription-recap-section__content__body__row.first-row > td {
  border-top: 1px solid var(--light-grey);
}
.subscription-recap-section__content__body__row > td:first-child {
  border-left: 1px solid var(--light-grey);
}
.subscription-recap-section__content__body__row > td:nth-child(2) {
  text-align: right;
}