.sheet-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 48px;
  height: 100%;
  width: 100%;
}

.sheet-plan {
  position: sticky;
  top: 48px;
  display: flex;
  flex-direction: column;
  width: 324px;
  padding-top: 48px;
}
.sheet-plan__part {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 8px;
  padding: 16px 0;
  border-top: 1px solid var(--light-grey);
  cursor: pointer;
}
.sheet-plan__part--text {
  font-size: 16px;
  font-weight: 500;
  color: var(--grey-700);
  transition: all 0.2s;
}
.sheet-plan__part--img {
  opacity: 0;
  transition: all 0.2s;
}
.sheet-plan__part:hover .sheet-plan__part--img, .sheet-plan__part.visible .sheet-plan__part--img {
  opacity: 1;
}
.sheet-plan__part:hover p.text-red, .sheet-plan__part.visible p.text-red {
  color: var(--red);
}
.sheet-plan__part:hover p.text-green, .sheet-plan__part.visible p.text-green {
  color: var(--green);
}
.sheet-plan__part:hover p.text-purple, .sheet-plan__part.visible p.text-purple {
  color: var(--purple);
}
.sheet-plan__part:hover p.text-blue, .sheet-plan__part.visible p.text-blue {
  color: var(--blue);
}
.sheet-plan__part:hover p.text-navy, .sheet-plan__part.visible p.text-navy {
  color: var(--navy);
}

.sheet-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
}

.sheet-title__container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.sheet-main-category {
  display: flex;
  padding: 8px;
  width: fit-content;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
}
.sheet-title__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}
.sheet-title {
  font-size: 40px;
  font-weight: 700;
}
.sheet-date {
  font-size: 14px;
  color: var(--breadcrumbs);
}

.sheet-parts-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.sheet-part {
  display: flex;
  flex-direction: column;
}
.sheet-part-title__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px 0;
  user-select: none;
  cursor: pointer;
}
.sheet-part-title {
  font-size: 20px;
  font-weight: 600;
}

.sheet-part__content table {
  width: 100%;
  border-collapse: collapse;
}
.sheet-part__content table thead, .sheet-part__content table th {
  background-color: var(--light-grey);
}
.sheet-part__content table td, .sheet-part__content table th {
  min-width: 2em;
  padding: .4em;
  border: 1px solid hsl(0, 0%, 75%);
}
/* italic fix */
.sheet-part__content i {
  font-style: italic;
}

.sheet-modal__title--container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.sheet-modal__title {
  font-size: 28px;
  font-weight: 600;
  color: var(--dark-navy);
}

.sheet-modal__text {
  font-size: 18px;
  text-align: center;
}

.sheet-modal__button--container {
  display: flex;
  justify-content: center;
  gap: 8px;
}


@media screen and (max-width: 1024px) {
  .sheet-plan {
    display: none;
  }

  .sheet-content {
    gap: 16px;
  }

  .sheet-main-category {
    padding: 4px 8px;
    font-size: 10px;
  }
  .sheet-title__content {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  .sheet-title {
    font-size: 30px;
  }
  .sheet-date {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .sheet-modal__title--container {
    gap: 8px;
  }
  .sheet-modal__title {
    font-size: 20px;
  }

  .sheet-modal__text {
    font-size: 16px;
  }
}