.book-content {
  display: flex;
  flex-direction: column;
  gap: 80px;
  width: 100%;
}

.book-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.book-item__header {
  display: flex;
  gap: 40px;
  width: 100%;
}

.book-item__image {
  width: 30%;
  height: 100%;
}
.book-item__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.book-item__header--content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 70%;
}

.book-item__header--content-header {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: flex-start;
}

.book-item__header--content-header-infos {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.book-item__title, .book-item__price {
  font-size: 24px;
  font-weight: 700;
}

.book-item__description {
  font-size: 14px;
}
.book-item__description.close {
  display: -webkit-box;
  max-height: 4.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.book-item__content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .book-item__header {
    gap: 16px;
  }

  .book-item__title, .book-item__price {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .book-item__header {
    gap: 8px;
  }

  .book-item__image {
    width: 40%;
  }

  .book-item__header--content {
    width: 60%;
  }

  .book-item__title, .book-item__price {
    font-size: 18px;
  }

  .book-item__link {
    align-self: center;
  }
}