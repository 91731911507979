.registration-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 200px;
  max-width: 480px;
  height: 100%;
}

.registration-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  padding: 24px 0;
  text-align: center;
}

.registration-title {
  font-size: 32px;
  font-weight: 700;
}

.form {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 24px;
  width: 100%;
}

.dual-input-container {
  display: flex;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
.input-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}
.input-label {
  font-size: 16px;
}
.input-error {
  color: var(--red);
  font-size: 12px;
}

.registration-buttons-container {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.step3-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.row-container {
  display: flex;
  align-items: center;
}
